<!-- eslint-disable vue/no-unused-vars -->
<template>
  <Layout :items="items">
    <PageHeader title="Site Management">
      <template #button>
        <div
          v-if="userRole <= 1"
          class="flex-shrink-0 text-end"
        >
          <button
            class="btn btn-primary w-100"
            data-bs-toggle="modal"
            href="#site-form-modal"
            @click="selectedSite = {}"
          >
            Create Macro Site
          </button>
        </div>
      </template>
    </PageHeader>
    <b-row>
      <b-col
        lg
        class="px-0 px-lg-2"
      >
        <div>
          <Pagination
            :meta-data="sitesMeta"
            @page-change="handlePageChange"
            @per-page-change="handlePerPageChange"
          >
            <template #button>
              <b-col
                md="6"
                lg="4"
              >
                <FormInputSelect 
                  v-model="filter.category" 
                  :options="typeOptions" 
                  label="Macro Site"
                />
              </b-col>
              <b-col
                md="6"
                lg="8"
              >
                <SearchBar
                  placeholder="Search Site"
                  @do-search="(val) => filter.keyword = val"
                  @reset-search="filter.keyword = null"
                />
              </b-col>
            </template>
            <template #table>
              <DataTable
                :fields="tableFields"
                :items="sites"
                :allow-local-sort="false"
                :is-loading="isLoading"
                @sort-changed="sortData"
              >
                <template #cell(name)="{ row }">
                  <router-link
                    :to="`/organization/${row.item.organization_id}/site/${row.item.id}`"
                    class="link-item-table custom-link"
                    v-html="highlightString(row.item.name, filter.keyword)"
                  />
                </template>
                <template #cell(coordinate)="{ row }">
                  {{ row.item.latitude }}, {{ row.item.longitude }}
                </template>
                <template #cell(category)="{ row }">
                  {{ capitalize(row.item.category ?? '') }}
                </template>
                <template #cell(action)="{ row }">
                  <div class="d-flex flex-shrink-0">
                    <b-button
                      v-if="userRole <= 1"
                      variant="outline-info"
                      class="me-2"
                      data-bs-toggle="modal"
                      href="#site-form-modal"
                      @click="selectedSite = row.item"
                    >
                      <i class="ri-edit-2-line" />
                    </b-button>
                    <b-button
                      v-if="userRole <= 1"
                      variant="outline-danger"
                      data-bs-toggle="modal"
                      href="#delete-modal"
                      @click="selectedSite = row.item"
                    >
                      <i class="ri-delete-bin-line" />
                    </b-button>
                  </div>
                </template>
              </DataTable>
              <div
                v-if="!sites.length"
                class="text-small text-center mb-3"
              >
                {{ filter.keyword ? 'Sorry, no sites match your search.' : 'Oops! There is no site yet.' }}
              </div>
            </template>
          </Pagination>
        </div>
      </b-col>
    </b-row>
    <SiteFormModal
      v-if="userRole <= 1"
      :site-detail="selectedSite"
      @update-data="getSites"
    />
    <DeleteModal 
      v-if="userRole <= 1"
      entity="Macro Site"
      :name="selectedSite.name ?? ''"
      @delete-data="doDelete"
    />
  </Layout>
</template>

<script>
  import FormInputSelect from '@/components/FormInputSelect.vue';

  import { mapGetters } from "vuex";
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/PageHeader";
  import DeleteModal from '@/components/DeleteModal.vue';

  import { capitalize, highlightString } from '@/utils/helper';

  import SiteFormModal from '@/components/site/SiteFormModal.vue';
  import Pagination from '@/components/Pagination.vue';
  import SearchBar from '@/components/SearchBar.vue';
  import DataTable from '@/components/DataTable.vue';

  import { useToast } from 'vue-toastification';
  import ToastDefault from '@/components/ToastDefault.vue';

  import { siteTypeOptions } from '@/utils/constant';

  export default {
    components: {
      Layout,
      PageHeader,
      DeleteModal,
      SiteFormModal,
      Pagination,
      SearchBar,
      DataTable,
      FormInputSelect
    },
    setup () {
      const toast = useToast();
      return {
        toast,
      }
    },
    data() {
      return {
        typeOptions: [
          { label: 'All Macro Sites', value: "" },
          ...siteTypeOptions
        ],
        items: [{
            text: "Site Management",
            href: "/site",
          },
        ],
        isLoading: false,
        sites: [],
        sitesMeta: {},
        tableFields: [{
            key: 'code',
            label: 'Code',
            class: 'text-break fit',
            sortable: true,
          }, {
            key: 'name',
            label: 'Name',
            class: 'text-break',
            thStyle: {
              minWidth: '8rem',
            },
            sortable: true,
          }, {
            key: 'category',
            label: 'Type',
            class: 'text-break fit',
            sortable: true,
          }, {
            key: 'address',
            label: 'Address',
            class: 'text-break',
          }, {
            key: 'coordinate',
            label: 'Coordinate',
          }, {
            key: 'action',
            label: 'Action',
          },
        ],
        filter: {
          keyword: null,
          category: '',
          per_page: 10,
          page: 1,
          sort_by: '',
          direction: ''
        },
        selectedSite: {},
      };
    },
    computed: {
      ...mapGetters('organization', ['userRole']),
      selectedOrganization() {
        return this.$store.state.organization.selectedOrganization;
      },
      processedFilter() {
        return {
          ...this.filter,
          category: this.filter.category == 'macro' ? null : this.filter.category,
          show_all: 0,
        }
      },
    },
    watch: {
      selectedOrganization: {
        immediate: true,
        handler(organizationId) {
          if (organizationId) {
            this.getSites()
          }
        },
      },
      filter: {
        deep: true,
        immediate: true,
        handler() {
          this.$router.push({ query: { ...this.filter } })
          this.getSites()
        }
      }
    },
    methods: {
      highlightString,
      capitalize,
      async getSites() {
        try {
          this.isLoading = true;
          
          const { data } = await this.$api.getOrganizationPlaces(this.selectedOrganization, { ...this.processedFilter });

          this.sites = data.data;
          this.sitesMeta = data.meta;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      async doDelete() {
        try {
          await this.$api.deleteOrganizationPlace(this.selectedSite.organization_id, this.selectedSite.id);           
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Deleted successfully',
              icon: 'ri-check-double-line',
            },
          };
          this.toast(toastContent);
          this.getSites()
        } catch (error) {
          console.log(error);

          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'danger',
              text: 'Process Failed',
            },
          };
          this.toast(toastContent);
        }
      },
      sortData(sort) {
        this.filter.sort_by = sort.sortBy
        this.filter.direction = sort.direction
      },
      handlePageChange(page) {
        this.filter.page = page
      },
      handlePerPageChange(perPage) {
        this.filter.page = 1
        this.filter.per_page = perPage
      }
    },
  };
</script>