<template>
  <div>
    <div
      ref="map"
      style="height: 100%"
    />
  </div>
</template>
<script>
export default {
  props: {
    latitude: {
      type: [String, Number],
      default: '0',
    },
    longitude: {
      type: [String, Number],
      default: '0',
    },
    radius: {
      type: String,
      default: '0',
    },
    mode: {
      type: String,
      default: '',
    },
    initialGeofence: {
      type: Array,
      default() {
        return []
      }
    },
    resetCounter: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      shape: null,
      geofence: [],
    }
  },
  watch: {
    latitude() {
      this.moveMarker()
    },
    longitude() {
      this.moveMarker()
    },
    radius() {
      this.moveMarker()
    },
    resetCounter: {
      immediate: false,
      handler() {
        this.initMap()
      }
    },
    mode: {
      immediate: false,
      handler() {
        this.handleDrawingMode()
      }
    },
  },
  mounted () {
    this.initMap();
  },
  methods: {
    async initMap() {
      window.map = new google.maps.Map(this.$refs['map'], {
        center: new google.maps.LatLng(Number(this.latitude), Number(this.longitude)),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: true,
      }) //.setClickableIcons(false)
      
      // Create Marker
      window.marker = new google.maps.Marker({
        position: new google.maps.LatLng(Number(this.latitude), Number(this.longitude)),
        map: window.map,
        // icon: {
        //   url: require('@/assets/images/googlemaps/site/default.svg'),
        //   origin: new google.maps.Point(0, 0),
        //   anchor: new google.maps.Point(13,13),
        //   scaledSize: new google.maps.Size(25, 25)
        // }
      });

      await google.maps.importLibrary("drawing")
      this.setupDrawing() 

      // Create Bound
      window.bounds = new google.maps.LatLngBounds();
      bounds.extend(new google.maps.LatLng(this.latitude, this.longitude))
      map.fitBounds(bounds)

      // Draw Geofence 
      if(this.initialGeofence?.length) {
        this.drawInitialGeofence()
      }

      // Add click event listener to move marker emit clicked latlng
      google.maps.event.addListener(map, "click", (event) => {
        if (event.placeId) {
          // Prevent tooltip when clicking point of interest places
          event.stop()
        }
        this.handleClick(event.latLng);
      })
    },

    setupDrawing() {
      window.DrawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        polygonOptions: {
          editable: true,
        }
      })
      DrawingManager.setMap(map)

      google.maps.event.addListener(DrawingManager, "overlaycomplete", (event) => {
        this.shape = event.overlay
        this.geofence = event.overlay.getPath().getArray()
        this.$emit('update-geofence', { shape: this.shape, geofence : this.geofence })
        
        this.addEventListenerToShape()
        
        DrawingManager.setDrawingMode(null)
      });

      this.handleDrawingMode()
    },

    addEventListenerToShape() {
      var self = this;
      google.maps.event.addListener(self.shape, 'click', (event) => {
        if (event.placeId) {
          // Prevent tooltip when clicking point of interest places
          event.stop()
        }
        this.handleClick(event.latLng);
      })
      // Drag / Insert / Undo point on existing polygon
      google.maps.event.addListener(self.shape.getPath(), 'set_at', function() {
        self.$emit('update-geofence', { shape: self.shape, geofence : self.geofence })
      });
      google.maps.event.addListener(self.shape.getPath(), 'insert_at', function() {
        self.$emit('update-geofence', { shape: self.shape, geofence : self.geofence })
      });
      google.maps.event.addListener(self.shape.getPath(), 'remove_at', function() {
        self.$emit('update-geofence', { shape: self.shape, geofence : self.geofence })
      });
    },

    drawInitialGeofence() {
      let newShape = new google.maps.Polygon({
        paths: this.initialGeofence,
        geodesic: true,
        editable: false,
      });
      newShape.setMap(map)

      var paths = newShape.getPaths();
			paths.forEach(function(path) {
				var ar = path.getArray();
				for(var i = 0, l = ar.length;i < l; i++) {
					window.bounds.extend(ar[i]);
				}
			});
			map.fitBounds(bounds);

      this.shape = newShape
      this.geofence = newShape.getPath().getArray()
      this.addEventListenerToShape()
      this.$emit('update-geofence', { shape: this.shape, geofence : this.geofence })
    },

    handleClick(location) {
      if (this.mode == 'marker') {
        const newLocation = { latitude: location.lat(), longitude: location.lng() }
        this.$emit('update-location', newLocation);
      }
    },

    handleDrawingMode() {
      setTimeout(() => {
        if (this.mode == 'geofence') {
          this.shape?.setEditable(true)
          if(!this.initialGeofence.length) {
            DrawingManager.setDrawingMode('polygon')
          }
        } else {
          this.shape?.setEditable(false)
          DrawingManager.setDrawingMode(null)
        }
      }, 100);
    },

    moveMarker() {
      marker.setPosition(new google.maps.LatLng(Number(this.latitude), Number(this.longitude)))
      map.panTo(new google.maps.LatLng(Number(this.latitude), Number(this.longitude)))
    },
  }
}
</script>
