<template>
  <b-modal
    id="site-form-modal"
    v-model="showModal"
    centered
    hide-footer
    @hidden="closeModal"
    @shown="initModal"
  >
    <template #title>
      {{ isEdit ? 'Edit' : 'Create' }} {{ isMacroSite ? 'Macro Site' : 'Micro Site in' }} <u>{{ parentDetail.name ?? '' }}</u>
    </template>
    <b-row class="d-flex justify-content-between mt-2 px-2">
      <div 
        v-for="(step, index) in formStep"
        :key="index"
        class="px-1"
        :class="step.value == 'separator' ? 'col-1' : 'col-3'"
      >
        <div v-if="step.value == 'separator'">
          <hr style="height: 2px;">
        </div>
        <div v-else>
          <input
            :id="`option-${index}`"
            ref="form-nav"
            v-model="selectedStep"
            type="radio"
            class="btn-check"
            :value="step.value"
          >
          <label
            class="d-flex btn btn-light mb-0 w-100 justify-content-center"
            :class="{ 'error': formStatus[step.text].error }"
            :for="`option-${index}`"
          >
            <div class="d-flex mx-auto">
              <i
                v-if="formStatus[step.text].error" 
                class="ri-error-warning-line"
                style="font-size: 16px"
              />
              <i 
                v-else-if="tabIndex == step.value" 
                class="ri-radio-button-line"
                style="font-size: 16px"
              />
              <i
                v-else-if="formStatus[step.text].complete" 
                class="ri-checkbox-circle-line"
                style="font-size: 16px"
              />
              <i
                v-else
                class="ri-checkbox-blank-circle-line"
                style="font-size: 16px"
              />
              <span class="ms-2 my-auto text-capitalize">
                {{ step.text }}
              </span>
            </div>
          </label>
        </div>
      </div>
    </b-row>
    <b-form
      class="mt-2"
      @submit.prevent="onSubmit"
    >
      <!-- Section 1 Form  -->
      <div v-if="tabIndex == 1">
        <!-- Name -->
        <b-form-group
          class="mb-3 required"
          :label="`${isMacroSite ? 'Macro Site' : 'Micro Site'} Name`"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            type="text"
            placeholder="Enter the site name"
            :state="v$.form.name.$dirty ? (!v$.form.name.$error ? null : false) : null"
          />
          <b-form-invalid-feedback
            v-if="v$.form.name.$error"
            class="d-inline"
          >
            <span
              v-for="(error, index) in v$.form.name.$errors"
              :key="index"
            >
              {{ error.$message + ' ' }}
            </span>
          </b-form-invalid-feedback>
          <FormCharacterCounter
            class="float-end"
            :field="form.name"
            :max-length="v$.form.name.maxLength.$params.max"
          />
        </b-form-group>
        <!-- Code -->
        <b-form-group
          class="mb-3"
          :label="`${isMacroSite ? 'Macro Site' : 'Micro Site'} Code`"
          label-for="input-code"
        >
          <b-form-input
            id="input-code"
            v-model="form.code"
            type="text"
            placeholder="Enter the site code"
            :state="errorResponse.code ? false : (v$.form.code.$dirty ? (!v$.form.code.$error ? null : false) : null)"
          />
          <b-form-invalid-feedback
            v-if="errorResponse.code"
            class="d-inline"
          >
            <span
              v-for="(error, index) in errorResponse.code"
              :key="index"
            >
              {{ error }}
            </span>
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="v$.form.code.$error"
            class="d-inline"
          >
            <span
              v-for="(error, index) in v$.form.code.$errors"
              :key="index"
            >
              {{ error.$message + ' ' }}
            </span>
          </b-form-invalid-feedback>
          <FormCharacterCounter
            class="float-end"
            :field="form.code"
            :max-length="v$.form.code.maxLength.$params.max"
          />
        </b-form-group>

        <b-form-group
          class="mb-3 required w-100"
          :label="`${isMacroSite ? 'Macro Site' : 'Micro Site'} Type`"
        >
          <FormInputSelect
            v-model="form.category"
            placeholder="Select the site type"
            :options="siteTypeOptions"
            :dropdown-only="true"
            :is-error="v$.form.category.$error ? true : false" 
          />
          <b-form-invalid-feedback
            v-if="v$.form.category.$error"
            class="d-inline"
          >
            <span
              v-for="(error, index) in v$.form.category.$errors"
              :key="index"
            >
              {{ error.$message + ' ' }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
        
        <b-form-group
          v-if="isMacroSite"
          class="mb-3"
          label="Macro Site Address"
          label-for="input-address"
        >
          <b-form-textarea
            id="input-address"
            v-model="form.address"
            placeholder="Enter the site address..."
            rows="3"
            :state="v$.form.address.$dirty ? (!v$.form.address.$error? null : false) : null"
          />
          <b-form-invalid-feedback
            v-if="v$.form.address.$error"
            class="d-inline"
          >
            <span
              v-for="(error, index) in v$.form.address.$errors"
              :key="index"
            >
              {{ error.$message + ' ' }}
            </span>
          </b-form-invalid-feedback>
          <FormCharacterCounter
            class="float-end"
            :field="form.address"
            :max-length="v$.form.address.maxLength.$params.max"
          />
        </b-form-group>
        <div class="clearfix" />
      </div>
      <div v-if="tabIndex > 1">
        <!-- Error Response Geofence -->
        <b-card-body
          v-if="tabIndex == 3 && formStatus['geofence'].error"
          class="rounded py-2 text-center"
          style="border: 1px solid #F06548;"
        >
          {{ errorResponse.geofence[0] ?? '' }}
        </b-card-body>

        <!-- Maps  -->
        <SiteFormModalMap
          class="mt-2"
          style="height: 450px"
          :latitude="form.latitude"
          :longitude="form.longitude"
          :mode="mapMode"
          :initial-geofence="siteGeofence"
          :reset-counter="resetCounter"
          @update-location="updateLatLng"
          @update-geofence="updateGeofence"
        />

        <!-- Latitude Longitude -->
        <b-row
          v-if="tabIndex == 2"
          class="mt-3"
        >
          <b-col
            v-for="(item, idx) in ['latitude', 'longitude']"
            :key="idx"
            md
          >
            <b-form-group
              class="mb-3 required"
              :label="item == 'latitude' ? 'Latitude' : 'Longitude'"
              :label-for="`input-${item}`"
            >
              <b-form-input
                :id="`input-${item}`"
                v-model="form[item]"
                type="number"
                :placeholder="`Enter the ${item} data (e.g. 44.5566778)`"
                :min="item == 'latitude' ? -90 : -180"
                :max="item == 'latitude' ? 90 : 180"
                step="0.00000001"
                required
                :state="v$.form[item].$dirty ? (!v$.form[item].$error ? null : false) : null"
              />
              <small :class="v$.form[item].$dirty ? (!v$.form[item].$error ? 'text-muted' : 'text-danger') : 'text-muted'">
                {{ item == 'latitude' ? `The latitude must be between -90 and 90.` : `The longitude must be between -180 and 180.` }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="tabIndex == 3"
          class="position-absolute"
          style="bottom: 15%; left: 45%"
        >
          <div class="d-flex">
            <b-button
              v-if="form.geofence?.length"
              class="mx-auto border-primary border-1"
              variant="light"
              @click="clearGeofence"
            >
              Clear Geofence 
            </b-button>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between gap-2 mt-2">
        <b-button
          variant="soft-danger"
          @click="resetForm"
        >
          Reset
        </b-button>
        <div class="d-flex gap-2">
          <b-button
            v-if="tabIndex > 1"
            variant="outline-primary"
            @click="goToPage(tabIndex - 1)"
          >
            Back
          </b-button>
          <b-button
            v-if="tabIndex < 3"
            variant="outline-primary"
            @click="goToPage(tabIndex + 1)"
          >
            Next
          </b-button>
          <b-button
            v-if="isEdit ? true : tabIndex == 3"
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { toRaw } from 'vue';

import FormInputSelect from '@/components/FormInputSelect.vue';

import { isEmptyObject } from '@/utils/helper';
import FormCharacterCounter from '@/components/FormCharacterCounter.vue';
import useVuelidate from "@vuelidate/core";
import { required, maxLength, maxValue, minValue, helpers } from "@vuelidate/validators";

import SiteFormModalMap from "./SiteFormModalMap";
import { useToast } from "vue-toastification";
import ToastDefault from "@/components/ToastDefault.vue";

import { siteTypeOptions } from '@/utils/constant';

export default {
  components: {
    SiteFormModalMap,
    FormCharacterCounter,
    FormInputSelect
  },
  props: {
    siteDetail: {
      type: Object,
      required: true,
    },
    parentDetail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  setup () {
    const toast = useToast();
    return {
      toast,
      v$: useVuelidate()
    }
  },
  data() {
    return {
      siteTypeOptions,
      form: {
        name: '',
        code: '',
        category: '',
        address: '',
        latitude: '',
        longitude: '',
        geofence: [],
        max_distance: 1,
      },
      tabIndex: 1,
      selectedStep: 1,
      showModal: false,
      drawnShape: {},
      tempGeofence: [],
      isLoading: false,
      resetCounter: 0,
      errorResponse: {},
    };
  },
  computed: {
    isEdit() {
      return !isEmptyObject(this.siteDetail);
    },
    isMacroSite() {
      return isEmptyObject(this.parentDetail);
    },
    selectedOrganization() {
      return this.$store.state.organization.selectedOrganization;
    },
    mapMode() {
      return this.tabIndex == 2 ? 'marker' : 'geofence' 
    },
    formStep() {
      return [
        { text: 'information', value: 1 },
        { text: '-', value: 'separator' },
        { text: 'marker', value: 2 },
        { text: '-', value: 'separator' },
        { text: 'geofence', value: 3 },
      ]
    },
    formStatus() {
      return {
        information: {
          error: !!(this.errorResponse.code),
          complete: !!(this.form.name && this.form.category),
        },
        marker: {
          error: false,
          complete: !!(this.form.longitude && this.form.latitude),
        },
        geofence: {
          error: !!(this.errorResponse.geofence),
          complete: !!(this.form.geofence.length),
        },
      }
    },
    siteGeofence() {
      if (this.siteDetail?.geofence?.coordinates[0]) {
        const geofence = this.siteDetail?.geofence?.coordinates[0]
        const geofenceArr = geofence.map((item) => ({
          "lat": item[1],
          "lng": item[0]
        }))
        geofenceArr.pop()
        return geofenceArr
      } else if (this.tempGeofence.length) {
        return this.tempGeofence
      } return []
    },
  },
  validations() {
    if(this.tabIndex == 1) {
      return {
        form: {
          name: {
            required: helpers.withMessage("Name field is required.", required),
            maxLength: helpers.withMessage("Maximum characters is 244.", maxLength(244)),
          },
          category: {
            required: helpers.withMessage("Type field is required.", required),
          },
          code: {
            maxLength: helpers.withMessage("Maximum characters is 20.", maxLength(40)),
          },
          address: {
            maxLength: helpers.withMessage("Maximum characters is 244.", maxLength(244)),
          },
        },
      }
    } else if(this.tabIndex == 2) {
      return {
        form: {
          latitude: {
            required: helpers.withMessage("Latitude field is required.", required),
            minValue: helpers.withMessage("The latitude must be between -90 and 90.", minValue(-90)),
            maxValue: helpers.withMessage("The latitude must be between -90 and 90.", maxValue(90)),
          },
          longitude: {
            required: helpers.withMessage("Longitude field is required.", required),
            minValue: helpers.withMessage("The longitude must be between -180 and 180.", minValue(-180)),
            maxValue: helpers.withMessage("The longitude must be between -180 and 180.", maxValue(180)),
          },
        },
      }
    } return {}
  },
  watch: {
    selectedStep(newValue) {
      this.goToPage(newValue)
      setTimeout(() => {
        this.$refs['form-nav'].forEach((nav) => nav.blur())
        if(this.tabIndex != newValue) {
          this.selectedStep = this.tabIndex
        }
      }, 1);
    },
    tabIndex() {
      this.selectedStep = this.tabIndex
    },
    'form.code': function () {
      if(this.errorResponse.code) {
        this.errorResponse.code = null
      }
    },
    'form.geofence': function () {
      if(this.errorResponse.geofence) {
        this.errorResponse.geofence = null
      }
    },
  },
  methods: {
    async onSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return
      }
      this.submitForm();
    },
    resetForm() {
      this.v$.$reset()
      if(this.tabIndex == 1) {
        const formItem = ['name', 'code', 'category', 'address']
        formItem.forEach((item)=> this.form[item] = this.siteDetail[item])
      } else if (this.tabIndex == 2) {
        if(!this.isEdit) {
          this.form.latitude = this.parentDetail?.latitude ?? -6.79800662
          this.form.longitude = this.parentDetail?.longitude ?? 110.83225608
        } else {
          this.form.latitude = this.siteDetail.latitude
          this.form.longitude = this.siteDetail.longitude
        }
      } else if (this.tabIndex == 3) {
        if(!this.isEdit) { 
          this.clearGeofence()
        } else {
          const geofence = this.siteDetail?.geofence?.coordinates[0] ?? []
          const geofenceArr = geofence.map((item) => ([
            item[1],
            item[0]
          ]))
          this.form.geofence = geofenceArr
          this.resetCounter += 1
        }
      }
    },
    async submitForm() {
      try {
        if (this.isEdit) {
          await this.$api.updateOrganizationPlace(this.siteDetail.organization_id, this.siteDetail.id, {
            ...this.form,
            parent_id: this.parentDetail?.id ?? ''
          });
        } else {
          await this.$api.createOrganizationPlace(this.selectedOrganization,  {
            ...this.form,
            parent_id: this.parentDetail?.id ?? ''
          });
        }
        this.$emit('update-data', this.isEdit ? 'Edited' : 'Saved');
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'secondary',
            text: this.isEdit ? 'Updated Successfully' : 'Saved Successfully',
            icon: 'ri-check-double-line'
          },
        };
        this.toast(toastContent);
        this.closeModal();
      } catch (error) {
        this.errorResponse = error.response?.data?.errors
        console.log(error);
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'secondary',
            text: 'Process Failed',
          },
        };
        this.toast(toastContent);
      }
    },
    closeModal() {
      this.showModal = false;
      this.tabIndex = 1;
      this.v$.$reset()
    },
    initModal() {
      this.v$.$reset()
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.siteDetail[key];
      });
      if(!this.form.max_distance) {
        this.form.max_distance = 1
      }
      if(!this.isEdit) {
        this.clearGeofence()
      } else {
        const geofence = this.siteDetail?.geofence?.coordinates[0] ?? []
        const geofenceArr = geofence.map((item) => ([
          item[1],
          item[0]
        ]))
        this.form.geofence = geofenceArr
        this.resetCounter += 1
      }
    },
    // Update lat lng when map is clicked
    updateLatLng(newLocation) {
      this.form.latitude = Number(newLocation.latitude).toFixed(8)
      this.form.longitude = Number(newLocation.longitude).toFixed(8)
    },
    updateGeofence(geofenceObj) {
      this.drawnShape = geofenceObj.shape
      this.tempGeofence = geofenceObj.geofence
      this.form.geofence = this.tempGeofence.map((item) => ([
        item.lat(),
        item.lng(),
      ]))
      if (this.form.geofence.length) {
        this.form.geofence.push(this.form.geofence[0])
      }
    },
    clearGeofence() {
      if (!isEmptyObject(this.drawnShape)) {
        // Vue creates proxies for objects; use toRaw() to fix this
        // without toRaw(), shape will reappear when zoom changed
        toRaw(this.drawnShape).setMap(null)
      }
      this.drawnShape = {}
      this.tempGeofence = []
      this.form.geofence = []
      if (window.DrawingManager) {
        DrawingManager.setDrawingMode('polygon')
      }
    },
    goToPage(page) {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return
      }
      if(page == 2) {
        if (this.form.latitude == null) {
          this.form.latitude = this.parentDetail?.latitude ?? -6.79800662
        } 
        if (this.form.longitude == null) {
          this.form.longitude = this.parentDetail?.longitude ?? 110.83225608
        }
      }
      if(page == 3) {
        if (!(this.form.latitude && this.form.longitude)) {
          this.goToPage(2)
          return
        }
      }
      this.tabIndex = page
    },
  },
};
</script>

<style scoped lang="scss">
.custom-map-button {
  background-color : '#fff';
  border : '2px solid #fff';
  border-radius : '3px';
  box-shadow : '0 2px 6px rgba(0,0,0,.3)';
  color : 'rgb(25,25,25)';
  cursor : 'pointer';
  font-family : 'Roboto,Arial,sans-serif';
  font-size : '16px';
  line-height : '38px';
  margin : '8px 0 22px';
  padding : '0 5px';
  text-align : 'center';
}
.invalid-nav {
  border-color: red !important;
}

.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.btn-light.active,
.btn-light.dropdown-toggle.show,
.btn-light:active {
  background-color: #F3F6F9;
  color: black;
  box-shadow: none;
  border: 1px solid #CED4DA;
  &.error {
    border: 1px solid #F06548;
  }
}
.btn-light {
  background-color: #FFFFFF;
  border: 1px solid #CED4DA;
  &.error {
    border: 1px solid #F06548;
  }
}
</style>
